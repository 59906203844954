import React from "react"
import { Link } from "gatsby"
import {
  Card,
  CardTitle,
  CardText,
  CardSubtitle,
  CardBody,
  Badge,
} from "reactstrap"
import Img from "gatsby-image"
import { slugify } from "../util/utilityFunctions"

const PostTag = ({ title, date, slug, tags, category, excerpt, fluid }) => {
  return (
    <Card>
      <div className="card-image mt-2">
        <Link to={`${category}/${slug}`}>
          <Img fluid={fluid} />
        </Link>
      </div>
      <CardBody>
        <CardTitle>
          <h4>
            <Link to={`${category}/${slug}`}>{title}</Link>
          </h4>
        </CardTitle>
        <CardSubtitle className="date-info text-info">
          <p>Publicado em {date}</p>
        </CardSubtitle>
        <CardText>{excerpt}</CardText>
        <ul className="post-tags">
          {tags.map((tag, index) => (
            <li key={index}>
              <Link to={`/tag/${slugify(tag)}`}>
                <Badge color="primary" className="text-uppercase">
                  {tag}
                </Badge>
              </Link>
            </li>
          ))}
        </ul>
        <Link to={slug} className="btn btn-sm btn-outline-primary float-right">
          Leia mais
        </Link>
      </CardBody>
    </Card>
  )
}

export default PostTag
