import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout2"
import PostTag from "../components/PostTag"

const TagPosts = ({ data, pageContext }) => {
  const { tag } = pageContext
  const { totalCount } = data.allMarkdownRemark
  const pageHeader = `${totalCount} postage${
    totalCount === 1 ? `m` : `ns`
  } marcada${totalCount === 1 ? `` : `s`} com "${tag}"`
  return (
    <Layout title={pageHeader}>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <PostTag
          key={node.id}
          slug={node.fields.slug}
          title={node.frontmatter.title}
          date={node.frontmatter.date}
          excerpt={node.excerpt}
          tags={node.frontmatter.tags}
          category={node.frontmatter.category}
          fluid={node.frontmatter.image.childImageSharp.fluid}
        />
      ))}
    </Layout>
  )
}

export const tagQuery = graphql`
  query($tag: String!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD/MM/YYYY")
            tags
            category
            image {
              childImageSharp {
                fluid(maxWidth: 650) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

export default TagPosts
